#header {
  border-top: 6px solid tomato;
}

#header .top {
  height: 100%;
}

#header li a span span.label {
  display: inline-block;
  padding: .2em .6em .3em;
}

#title {
  text-transform: uppercase;
}

#logo h1 span {
  color: #ff7979;
}

#logo {
  position: relative;
  margin: 0.75em 1.5em 1.5em 1.5em;
  min-height: 48px;
  cursor: default;
}

.panel-default > .panel-heading {
  color: #FFF;
  background-color: rgba(33, 37, 40, 0.87);
  border-color: rgba(221, 221, 221, 0.66);
}

.form-control {
  height: 50px;
  font-size: 16px;
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
  margin-top: 15px !important;
}

.checkbox label, .radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
  font-size: 18px;
}

.padding-30 {
  padding: 30px;
}

.btn-primary {
  background-color: tomato !important;
  border: none;
}

#header hr {
  border: 0;
  border-top: solid 1px #4A4A4A;
}

#header ul, #header ol {
  margin-bottom: 0.4em;
}

#header .dropdown-menu {
  background-color: tomato;
}

#header .dropdown-menu:after {
  position: absolute;
  bottom: -7px;
  left: 15px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-top: 7px solid tomato;
  border-left: 7px solid transparent;
  content: '';
}

#header .dropdown-menu > li > a {
  color: #fff;
  border-bottom: none;
  font-size: 16px;
}

#header .dropdown-menu > li > a:focus, #header .dropdown-menu > li > a:hover {
  background-color: #ff7961;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

#shortcuts {
  border-radius: 50%;
  width: 46px;
  height: 46px;
  margin-bottom: 10px;
}

body {
  background-color: #f5fafa;
}

.dashboard header {
  background-color: rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0.5em;
}

.dashboard header h4 {
  font-weight: 600 !important;
  text-align: left;
  padding-left: 1em;
}

.dashboard header h4 span {
  margin-right: 0.3em;
}

.dashboard header h4 span i {
  color: rgba(0, 0, 0, 0.2);
}

.dashboard #content {
  padding: 0;
}

.dashboard .panel {
  font-size: 18px;
  text-align: left;
  border: 1px solid #ddd;
}

.dashboard .panel .panel-heading {
  background-color: rgba(33, 37, 40, 0.79);
}

.dashboard .panel-info {
  border-color: rgba(188, 232, 241, 0.63);
}

.dashboard .panel-info .panel-heading {
  color: #31708f;
  background-color: rgba(223, 244, 254, 0.55) !important;
  border-color: #fff;
  padding: 5px 10px;
}

#nav ul li a:hover, #nav ul li a:active, #nav ul li a .active {
  background-color: #16191b;
  color: #fff;
}

#nav ul li a:hover span::before, #nav ul li a:active span::before, #nav ul li a .active span::before {
  color: tomato;
}

#header .bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 31%;
}

.fc-toolbar {
  margin-bottom: 0.5em;
}

.fc h2 {
  font-size: 1em;
  padding: 0.6em 0 0 0.6em;
  font-weight: bold;
}

.fc table {
  margin-bottom: 0 !important;
}

.fc .fc-right {
  padding: 0.4em 0.4em 0 0;
}

.fc .fc-button, .fc .fc-state-default {
  background-color: tomato !important;
  border: none;
}

.fc button {
  vertical-align: baseline;
  height: 1.6em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  line-height: 1em;
  padding: 0 .6em;
  font-size: 1em;
  white-space: nowrap;
  cursor: pointer;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 3em;
}

.fc-state-default {
  background-color: tomato;
  background-image: none;
  border: none;
  color: #f1f1f1;
  text-shadow: none;
  box-shadow: none;
}

.fc-title {
  font-size: 12px;
}

#search-box .form-control {
  height: 35px;
  font-size: 14px;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 0 !important;
  color: #5d5d5d;
}

#search-box .btn {
  margin: 0;
  border: none;
  vertical-align: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#search-box .input-group-addon {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #5d5d5d;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#search-box .input-group-addon i {
  color: #B1B1B1;
}

.has-error .help-block {
  color: #a94442;
  text-align: left;
  font-size: 18px;
}

.fc-day-header {
  background-color: rgba(0, 0, 0, 0.1);
}

.fc-sun.fc-day-header {
  background-color: rgba(0, 0, 0, 0.1);
}

.fc-sun {
  background-color: rgba(0, 0, 0, 0.05);
}

th {
  font-weight: 600 !important;
}

.link-row:hover {
  cursor: pointer;
}

.activeJobs {
  font-size: 16px;
}

form textarea {
  min-height: 7em;
}

.toolbar a {
  margin-bottom: 0;
}

.text-muted {
  color: #bbbbbb;
}

#participants-list {
  text-align: left;
  font-size: 16px;
}

#participants-list .list-group-item {
  border-color: rgba(238, 238, 238, 0.66);
}

.list-group-item-header {
  background-color: rgba(238, 238, 238, 0.66);
}

.list-group-item-header h4 {
  text-align: center;
}

#message-toolbar .btn {
  margin-bottom: 0;
}

#message-textarea {
  width: 100%;
  border: none;
  margin-top: 10px;
  font-size: 18px;
  padding: 0;
  box-shadow: none;
}

.dashboard p {
  margin-bottom: 1em;
}

button.btn-default {
  color: rgba(74, 74, 74, 0.6) !important;
  border: 1px solid rgba(74, 74, 74, 0.6) !important;
  padding: 0.65em 2.4em 0.65em 2.4em;
}

#message-toolbar .panel-heading {
  background-color: rgba(33, 37, 40, 0.7) !important;
}

.unread {
  background-color: rgba(0, 158, 168, 0.07) !important;
}

#show-participants {
  margin-left: 5px;
}

#toast-container > div {
  font-size: 17px !important;
  line-height: 1.2em;
  padding: 10px 10px 10px 50px;
  box-shadow: 0 0 5px #999;
}

#toast-container > div:hover {
  box-shadow: 0 0 5px #999;
}

.ui-widget {
  font-family: 'Source Sans Pro', sans-serif;
}

.ui-datepicker-div {
  z-index: 999 !important;
}

.ui-datepicker th {
  padding: .3em .3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.ui-datepicker td .ui-state-hover {
  background-image: none;
  background-color: tomato;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  background-color: tomato;
  background-image: none;
  border: none;
}

.ui_tpicker_hour_label, .ui_tpicker_minute_label {
  height: 20px;
}

#create-event form textarea {
  min-height: 4em;
}

.ui-timepicker-select {
  color: #fff !important;
}

.event-general {
  background-color: rgba(58, 135, 173, 0.78);
  border-color: rgba(58, 135, 173, 0.78);
}

.event-private {
  border: 1px dashed black !important;
}

.event-meeting {
  background-color: rgba(232, 96, 0, 0.76);
  border-color: rgba(232, 96, 0, 0.76);
}

.event-annual-leave {
  background-color: rgba(0, 142, 58, 0.74);
  border-color: rgba(0, 142, 58, 0.74);
}

.heading-small {
  color: rgba(255, 255, 255, 0.9);
  padding: 0 15px !important;
}

.dl-horizontal dt {
  font-weight: 600;
}

.dl-horizontal dt, .dl-horizontal dd {
  margin-bottom: 10px;
  line-height: 1.4;
}

div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  padding-right: 10px;
  text-align: right !important;
}

div.dataTables_length {
  padding-left: 10px;
}

div.dataTables_length select.input-sm {
  font-size: 16px !important;
  padding: 0 5px !important;
}

div.dataTables_wrapper .col-sm-12 {
  padding-top: 0 !important;
}

div.dataTables_wrapper div.dataTables_filter input {
  height: 40px !important;
}

div.dataTables_paginate {
  padding-right: 10px;
}

div.dataTables_wrapper div.dataTables_info {
  padding-left: 10px;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: rgba(255, 99, 71, 0.75);
  border-color: rgba(255, 99, 71, 0.75);
}

input[type="text"], input[type="password"], .ui-autocomplete-input, textarea, .uneditable-input {
  display: inline-block;
  padding: 4px;
  font-size: 16px;
  line-height: 18px;
  color: #808080;
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
  padding: 0;
}

.dt-buttons.btn-group {
  padding-left: 10px;
}

.btn-secondary {
  background-color: rgba(255, 99, 71, 0.78) !important;
  border: none;
  margin-bottom: 0;
  color: #fff;
}

.btn-secondary:hover {
  color: #fff;
}

@media print {
  body {
    font-size: 14px;
  }
}

.fixedHeader-floating {
  font-size: 18px !important;
  border-bottom: 1px solid #ccc;
}

#opc_button {
  padding: 0 0.7em;
  font-size: 15px;
  margin-top: 0.2em;
}

#opc_dropdown {
  padding: 0.7em;
  font-size: 15px;
  margin-top: 0.6em;
}

.btn-clear {
  background-color: transparent;
  border-color: transparent;
  color: #8b8b8b !important;
}

.btn-clear:hover {
  background-color: transparent;
  border-color: transparent;
  color: #363636 !important;
}

.activeJobs .danger td {
  color: #ff2804;
}

#admin-nav {
  display: block;
  width: 100%;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

#admin-nav a {
  text-decoration: none;
  border-bottom: none;
}

#table-log {
  font-size: 15px;
}

.log-list {
  font-size: 15px;
}

.log-list li a {
  padding: 5px 10px;
}

#view-as {
  margin-bottom: 0;
  margin-top: -0.3em;
}

#view-as label {
  text-transform: none;
  font-size: 16px;
  display: inline-block;
  margin-right: 8px;
}

#view-as select {
  display: inline-block;
  width: 50%;
  height: 35px;
  font-size: 16px;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: none;
  color: #5d5d5d;
  margin: 0;
  padding: 0 0 0 10px !important;
}

.task-complete td {
  text-decoration: line-through;
}

#timer {
  display: block;
  z-index: 999;
  background-color: rgba(34, 38, 41, 0.95);
  width: 40px;
  height: 90px;
  margin-top: -50px;
  padding: 0;
  text-align: center;
  position: fixed;
  top: 50%;
  right: 0;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 15px;
}

#timer #job-list {
  float: left;
  display: none;
  width: 460px;
  text-align: left;
  font-size: 12px;
  margin: 0;
  border-left: 1px solid rgba(102, 102, 102, 0.51);
  height: 100%;
}

#timer #job-list li {
  line-height: 1;
  padding: 5px;
  color: #888;
}

#timer #job-list li strong {
  font-weight: 600;
}

#timer #job-list .job-timer-items {
  display: none;
}

#timer #job-list li.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

#timer #job-list li.active strong {
  color: #fff;
}

#timer #job-list li.active .job-timer-items {
  display: block;
}

#timer .wrap {
  width: 40px;
  display: inline-block;
  float: left;
}

#timer #clock {
  border: none;
  background-color: transparent;
  width: 40px;
  font-size: 12px;
  text-align: center;
}

#timer #clock:active, #timer #clock:focus {
  box-shadow: none;
}

#timer div {
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 1;
}

#timer #controls, #timer #timer-icon {
  padding: 5px 5px 0 5px;
  font-size: 18px;
}

#timer #active-job {
  margin-top: 3px;
  font-size: 11px;
}

#timer #active-job:hover {
  color: #fff;
  text-decoration: underline;
}

.timer-running {
  background-color: #222629 !important;
  color: #fff;
}

.timer-running #clock {
  color: #fff;
}

.timer-running #timer-icon span {
  color: #7bff53;
}

#handle {
  display: block;
  background-color: #222629;
  width: 25px;
  height: 70px;
  position: absolute;
  top: 50%;
  right: -25px;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#handle:hover span {
  color: white;
}

#handle span {
  position: relative;
  top: 75%;
  transform: translateY(-150%);
  left: -14%;
  color: rgba(255, 255, 255, 0.26);
  font-size: 1em;
}

#create-event #title {
  text-transform: none;
}

td ul li {
  list-style: disc;
}

body, html {
  height: 100%;
}

[ng-cloak].splash {
  display: block;
}

.splash {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  width: 100%;
  filter: alpha(opacity=80);
  opacity: 0.8;
  background: #F1F6F6;
}

#main .splash h2 {
  margin-bottom: 20px;
  top: 200px;
  position: absolute;
  text-align: center;
  display: block;
  width: 100%;
  border-bottom: none;
  color: #333;
}

#app {
  position: relative;
  width: 100%;
}

#app .form-control {
  height: 35px;
  font-size: 16px;
}

.form-control:focus {
  border-color: tomato;
  outline: 0;
  box-shadow: none !important;
}

.modal-dialog label {
  font-size: 18px !important;
}

.modal-dialog .form-control {
  height: 39px;
  font-size: 18px;
}

.modal-dialog .form-group {
  margin-bottom: 2px;
}

.modal-dialog .modal-header {
  background-color: rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.modal-dialog h3 {
  font-size: 1em;
}

.modal-dialog .modal-footer .btn-primary {
  border: 1px solid rgba(255, 40, 4, 0.6) !important;
  padding: 0.65em 2.4em 0.65em 2.4em;
}

.modal-dialog .modal-footer a {
  font-size: 18px;
}

#header li a span span.label {
  display: inline-block;
  padding: .1em .4em .2em;
  border-radius: 6px;
  font-size: 0.6em;
  position: absolute;
  right: -1.2em;
  top: 5px;
}

.drop-box {
  min-height: 170px;
  line-height: 170px;
  text-align: center;
  border: 4px dashed #eee;
}

.dragover {
  border: 4px dashed tomato;
  color: tomato;
}

.btn-grey {
  background-color: #7b8084 !important;
  color: #fff;
  border: none;
}

.btn-grey:hover {
  color: #fff;
  background-color: #51565a !important;
}

.toolbar {
  padding: 5px 10px 10px 10px;
  background-color: rgba(197, 197, 197, 0.45) !important;
  border-bottom: 1px solid rgba(197, 197, 197, 0.5);
}

.toolbar .btn {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  font-size: 12pt;
}

.toolbar .btn-primary {
  background-color: rgba(255, 68, 34, 0.89) !important;
}

.toolbar-app {
  border: 1px solid rgba(197, 197, 197, 0.5);
}

#Status {
  text-transform: uppercase;
  padding: 0;
  position: absolute;
  top: 25px;
  left: 10px;
  font-size: 18px;
}

#Status span {
  margin-right: 10px;
  margin-top: 10px;
}

#app {
  padding: 15px 0 0 0 !important;
}

#app .saving {
  font-size: 14px;
  margin-left: 20px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: none;
}

#app dd small {
  font-size: 14px;
  color: rgba(74, 74, 74, 0.65);
  margin-left: 5px;
}

#app dd .dropdown-toggle {
  text-decoration: none;
  color: #428bca;
  border-bottom: dashed 1px #428bca;
}
